@import '~@fluentui/react/dist/css/fabric.css';
@import '~@rocketcodeit/react-components/dist/index.css';
@import '/node_modules/react-grid-layout/css/styles.css';
@import '/node_modules/react-resizable/css/styles.css';

body {
  /*background: #F2F4F8;*/
  background: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#rc-sidebar {
  background: rgb(250, 250, 250);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ms-Button {
  /*border-radius: 4px;*/
}

.ms-Dropdown-title {
  /*border-radius: 4px;*/
}

.ms-Dropdown-title:after {
  /*border-radius: 4px;*/
}

/*
.ms-DetailsList {
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px;
}
*/

.ms-MessageBar {
  /*border-radius: 4px;*/
}

.rc-margin-b-8 {
  margin-bottom: 8px;
}

.rc-margin-b-16 {
  margin-bottom: 16px;
}

.rc-margin-b-24 {
  margin-bottom: 24px;
}

.rc-margin-b-32 {
  margin-bottom: 32px;
}



.rc-margin-t-8 {
  margin-top: 8px;
}

.rc-margin-t-16 {
  margin-top: 16px;
}

.rc-margin-t-24 {
  margin-top: 24px;
}

.rc-margin-t-32 {
  margin-top: 32px;
}

::-webkit-scrollbar{
  display: none;
}

.salesChannelOptions  .ms-ChoiceFieldLabel {
  font-size: 11px;
}
